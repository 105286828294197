<template>
  <div class="map-selection-item-settings" @click.stop>
    <CDropdown color="primary" toggler-text="Dropdown Button" class="mt-2">
      <template #toggler>
        <button class="btn-plain btn-settings" title="map settings">
          <CIcon name="cil-options" />
        </button>
      </template>
      <CDropdownItem class="map-menu-item">
        <CIcon name="cil-data-transfer-down" />
        <p class="map-menu-item">Download</p>
      </CDropdownItem>
      <CDropdownItem class="map-menu-item">
        <CIcon name="cil-share-alt" />
        <p class="map-menu-item">Share</p>
      </CDropdownItem>
      <CDropdownItem class="map-menu-item">
        <CIcon name="cil-trash" />
        <p class="map-menu-item">Delete</p>
      </CDropdownItem>
    </CDropdown>
  </div>
</template>
<script>
export default {
  name: "MapSelectionItemSettings",
  props: {
    downloadUrl: {
      type: String,
      default: null
    },
    shareLink: {
      type: String,
      default: null
    }
  }
}
</script>
<style lang="scss">
p.map-menu-item {
  margin-left: 8px;
  margin-bottom: 0px;
}
a.dropdown-item.map-menu-item {
  margin: 0px;
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.dropdown-menu.show {
  margin: 0px;
  padding: 0px;
  min-width: auto;
}
button.btn-plain.btn-settings:hover,
  button.btn-plain.btn-settings:focus,
  button.btn-plain.btn-settings {
  background-color: inherit;
  box-shadow: none;
}

button.btn-plain.btn-settings:focus {
  outline: 0px solid #fff;
  outline-offset: -4px;
}

button.btn-plain.btn-settings:active {
  transform: scale(0.99);
}
</style>
