<template>
  <div class="map-selection-item" @click="$emit('map-click', eventInfo)">
    <div class="map-selection-item__wrapper">
      <div class="map-selection-item__thumbnail">
        <img class="map-logo" :src="imgSrc" alt="map logo" draggable="false" />
      </div>
      <div class="map-selection-item__description">
        <div
          v-html="title.toUpperCase()"
          class="map-selection-item__title"
        ></div>
        <div v-html="presenter" class="map-selection-item__presenter"></div>
        <div
          v-html="venue"
          class="map-selection-item__venue"
          v-if="venue"
        ></div>
        <div
          v-html="dates"
          class="map-selection-item__dates"
          v-if="dates"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import MapSelectionItemSettings from "@/components/map/MapSelectionItemSettings";
export default {
  name: "MapSelectionItem",
  components: {
    MapSelectionItemSettings,
  },
  props: {
    eventInfo: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      required: true,
      default: "<span class='absent-prop'>(Event Name)</span>",
    },
    presenter: {
      type: String,
      required: true,
      default: "<span class='absent-prop'>(Event Presenter)</span>",
    },
    venue: {
      type: String,
      required: true,
      default: "<span class='absent-prop'>(Event Venue)</span>",
    },
    dates: {
      type: String,
      default: "<span class='absent-prop'>(Event Dates)</span>",
    },
  },
  computed: {
    imgSrc() {
      if (this.eventInfo.hasOwnProperty("image-key")) {
        const imgKey = this.eventInfo["image-key"];

        if (imgKey != null && imgKey.hasOwnProperty("img")) {
          const img = this.eventInfo["image-key"].img;

          var base64String = "";

          var binary = "";
          var bytes = new Uint8Array(img.data.data);
          var len = bytes.byteLength;
          for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          base64String = btoa(binary);
          return `data:image/${img.contentType};base64,${base64String}`;
        }
      }

      return "@/assets/img/app/transparent.gif";
    },
  },
};
</script>
<style lang="scss" scoped>
.map-selection-item {
  max-width: 300px;
  color: rgb(67, 78, 101);
  border-radius: 5px;
  box-shadow: 0px 0px 12px 6px hsla(180, 0%, 40%, 0.35);
  &:hover {
    cursor: pointer;
  }
  &__wrapper {
    border: 1px solid rgb(67, 78, 101);
    border-radius: 5px;
    margin: 0px;
    background-color: white;
  }
  .c-dark-theme &__wrapper {
    border: 1px solid rgb(67, 78, 101);
  }
  &__thumbnail {
    height: 150px;
    margin: 0px;
  }
  &__description {
    border-top: 1px solid rgb(67, 78, 101);
    width: 100%;
    height: 94px;
    padding: 0px;
    margin: 0;
  }

  .c-dark-theme &__description {
    border-top: 1px solid rgb(67, 78, 101);
  }
  &__venue,
  &__dates,
  &__title,
  &__presenter {
    padding: 0px 8px;
    font-family: "Helvetica Neue", "Arial", "Helvetica", "sans-serif";
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__venue {
    font-size: 12px;
    margin-bottom: -4px;
  }
  &__dates {
    font-size: 12px;
  }
  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 8px;
    margin-bottom: -2px;
  }
  &__presenter {
    font-size: 16px;
    margin-bottom: 4px;
  }
}
img.map-logo {
  display: block;
  height: 150px;
  width: 298px;
  padding: 10px;
  object-fit: contain;
  border: 0;
  overflow: hidden;
}
</style>

<style lang="scss">
span.absent-prop {
  color: hsla(0, 0%, 60%, 1);
}
</style>
