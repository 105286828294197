<template>
  <div ref="page" class="map-selection mx-0">
    <div class="c-app" :class="{ 'c-dark-theme': $store.state.ui.darkMode }">
      <CWrapper>
        <CHeader>
          <CHeaderBrand to="/">
            <img
              class="brand-logo"
              :src="
                require('@/assets/brand/brickworks-skyfall-horizontal-dark.svg')
              "
              alt=""
            />
          </CHeaderBrand>
          <CHeaderNav class="header-nav">
            <CHeaderNavItem class="pl-2 pr-0 c-d-legacy-none header-nav-item">
              <settings-dropdown :darkLight="false" />
            </CHeaderNavItem>
          </CHeaderNav>
        </CHeader>
        <div class="container mt-4">
          <div v-if="!loading" class="maps-grid">
            <div v-for="(map, index) in mapData" :key="index" class="m-4">
              <map-selection-item
                :eventInfo="map"
                :title="map.name"
                :venue="map.venueName"
                :dates="map.dates"
                :presenter="map.idCompany.name"
                @map-click="handleMapClick"
              />
            </div>
          </div>
        </div>
        <portal-target name="modal" />
        <router-view class="h-100" />
      </CWrapper>
    </div>
  </div>
</template>
<script>
import MapSelectionItem from "@/components/map/MapSelectionItem";
import SettingsDropdown from "@/components/ui/SettingsDropdown";
export default {
  name: "MapSelection",
  components: {
    SettingsDropdown,
    MapSelectionItem,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    mapData() {
      return this.$store.state.map.maps;
    },
  },
  mounted() {
    this.loadMaps();
  },
  methods: {
    loadMaps() {
      this.loading = true;
      let loader = this.$loading.show({
        container: this.$refs.page,
      });
      this.$store.dispatch("map/loadMaps").then((rsp) => {
        loader.hide();
        this.loading = false;
      });
    },
    handleMapClick(eventInfo) {
      this.$store
        .dispatch("map/loadMapData", eventInfo._id)
        .then((response) => {
          this.$router.push({ name: "map", query: { mapId: eventInfo._id } });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
div.map-selection {
  background-color: #434e65;
}
.logo-img {
  margin-left: 10px;
}

.maps-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.map-select-prompt {
  justify-content: flex-end;
  align-self: flex-end;
  font-family: "Helvetica Neue", "Arial", "Helvetica", "sans-serif";
  font-size: 30px;
  font-weight: 400;
}

div.c-wrapper {
  background-color: #434e65;
  background-image: url("~@/assets/brand/brickworks-contours.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

div.container-fluid {
  background-color: none;
}

.brand-logo {
  max-width: 200px;
  text-align: left;
  height: 56px;
  margin-left: 20px;
  margin-right: auto;
  flex-grow: 1;
}

header.c-header,
.c-app:not(.c-legacy-theme) .c-header.c-header-fixed {
  justify-content: space-between;
  background-color: #434e65;
  border-bottom: 1px solid black;
  box-shadow: 0px 6px 12px 2px hsla(180, 0%, 10%, 0.25);
}
</style>

<style lang="scss">
div.c-app {
  background-color: white;
}
</style>
